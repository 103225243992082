import React from 'react';
import Notifications from 'react-notify-toast';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { styled, helpers } from 'react-free-style';

import { Login } from '@united-talent-agency/julius-frontend-components';
import {
  PrivateRoute,
  LoginCallback,
  redirectToAzure,
  hasAccess,
} from '@united-talent-agency/julius-frontend-store';
import { errors, notifications } from '@united-talent-agency/components';

import { defaultUrl, apiServerUrl } from './support/urls';
import PhoneSheet from './containers/PhoneSheet';

import aktivGroteskLight from './assets/fonts/aktiv-grotesk-light.woff';
import aktivGroteskRegular from './assets/fonts/aktiv-grotesk-regular.woff';
import aktivGroteskBold from './assets/fonts/aktiv-grotesk-bold.woff';

import * as colors from './styles/colors';

const localVersion = require('./git.json').sha;
const { NewRelease } = notifications;
const { REACT_APP_RELEASE_CHECK_INTERVAL_SECONDS, REACT_APP_RELEASE_CHECK_FORCE_RELOAD } =
  process.env;
const forceReload = REACT_APP_RELEASE_CHECK_FORCE_RELOAD === true.toString();

const LoginComponent = () => (
  <Login defaultUrl={defaultUrl} apiServerUrl={apiServerUrl} redirectToAzure={redirectToAzure} />
);

const Routes = (props) => {
  // const location = useLocation();

  // useEffect(() => {
  // TODO: Tracking
  // Page view: `${location.pathname}${location.search}`
  // }, [location]);

  const authorizationCheck = () => hasAccess('access', 'web', 'phonesheet');

  const PhoneSheetPrivateRouteComponent = (routeProps) => (
    <PrivateRoute
      component={(_props) => <PhoneSheet {...routeProps} {..._props} />}
      dispatch={props.dispatch}
      apiServerUrl={apiServerUrl}
      deskRequiredComponent={errors.DeskRequired}
      authorizationCheck={authorizationCheck}
      forbiddenComponent={errors.Forbidden}
    />
  );

  return (
    <div className={props.styles.container}>
      <Helmet>
        <title>PHONESHEET</title>
      </Helmet>
      <Notifications />
      <NewRelease
        currentVersion={localVersion}
        intervalSeconds={REACT_APP_RELEASE_CHECK_INTERVAL_SECONDS}
        forceReload={forceReload}
      />
      <Switch>
        <Route exact path="/login" component={LoginComponent} />
        <Route
          path="/login-callback"
          component={() => {
            LoginCallback(props);
            return <></>;
          }}
        />
        <Route exact path="/" component={PhoneSheetPrivateRouteComponent} />
        <Route path="*">{errors.NotFound}</Route>
      </Switch>
    </div>
  );
};

const withStyles = styled(
  {
    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: colors.background,
      fontFamily: 'aktiv-grotesk, sans-serif',
    },
  },
  {
    css: {
      '*': {
        boxSizing: 'border-box',
      },
      html: {
        backgroundColor: colors.background,
        fontFamily: 'aktiv-grotesk, sans-serif',
        minWidth: 320,
        fontSize: 15,
        lineHeight: 1.3,
      },
    },
    rules: [
      [
        '@font-face',
        {
          fontFamily: 'aktiv-grotesk',
          src: helpers.url(aktivGroteskRegular),
          fontWeight: 400,
          fontStyle: 'normal',
        },
      ],
      [
        '@font-face',
        {
          fontFamily: 'aktiv-grotesk',
          src: helpers.url(aktivGroteskBold),
          fontWeight: 700,
          fontStyle: 'normal',
        },
      ],
      [
        '@font-face',
        {
          fontFamily: 'aktiv-grotesk',
          src: helpers.url(aktivGroteskLight),
          fontWeight: 300,
          fontStyle: 'normal',
        },
      ],
    ],
  }
);

const withState = connect();

export default withStyles(withState(Routes));
