import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { helpers, styled } from 'react-free-style';
import classnames from 'classnames';
import {
  StatusInput,
  PhoneInput,
  ContactTypeSelector,
  DatePicker,
  Input,
  fromStringToE164,
} from '@united-talent-agency/components';
import * as colors from '../../../styles/colors';
import * as elements from '../../../styles/elements';
import * as sizes from '../../../styles/sizes';
import { CALL_FORM, CONTACT_INPUT } from '../../../support/cypressTags';

import {
  TextArea,
  PersonInput,
  DoneButtons,
} from '@united-talent-agency/julius-frontend-components';

import {
  searchDeskContacts,
  getPersonPrivateContacts,
} from '@united-talent-agency/julius-frontend-store';
import { getPeopleByTypeBuckets } from '../../../api/people';
import { getOutlookContacts } from '../../../api/outlook';

import {
  existingContactPhone,
  existingContactNotPhone,
  updateDeskAddress,
} from '../../../support/contact';
import { useDebouncedCallback } from 'use-debounce';
import withHooksHOC from '../../../hocs/withHooksHOC';

const CONTACT_PRIORITIES = [
  'Office Phone',
  'Assistant Phone',
  'Mobile Phone',
  'Home Phone',
  'Email',
  'Assistant Email',
  'Personal Email',
  'Message',
  'Fax Number',
  'Unknown',
];

const Component = ({
  onChange,
  callForm = {},
  callTodo = {},
  deskId,
  dispatch,
  onSave,
  addToast,
  styles = {},
  isNotesFocus,
  statuses,
  onCancel,
}) => {
  // Refs
  const nameInput = useRef();
  const notesInput = useRef(null);

  // States
  const [isSaving, setIsSaving] = useState(false);
  const [dateString, setDateString] = useState('');
  const [timeString, setTimeString] = useState('');
  const [twelveHourPeriod, setTwelveHourPeriod] = useState('');
  const [personSearchResults, setPersonSearchResults] = useState([]);
  const [outlookSearchResults, setOutlookSearchResults] = useState([]);
  const [deskContactResults, setDeskContactResults] = useState([]);
  const [recipientIdPrivateContacts, setRecipientIdPrivateContacts] = useState([]);
  const [deskAddressBookEntryContacts, setDeskAddressBookEntryContacts] = useState([]);
  const [initialTimeString, setInitialTimeString] = useState('');

  useEffect(() => {
    const { _id, recipientId, recipientName } = callTodo;
    const recipient = { name: recipientName, id: recipientId };

    const recipientContacts = updateDeskAddress(_id, deskId, recipient, dispatch);
    if (recipientContacts) {
      setDeskAddressBookEntryContacts(recipientContacts);
    }

    const currentTimeDisplay = new Date(callTodo.occurrence_date).toLocaleString(
      navigator.language,
      {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }
    );

    const currentDateString = currentTimeDisplay.split(' ')[0].slice(0, -1);
    const currentTimeString = currentTimeDisplay.split(' ')[1];
    const currentTwelveHourPeriod = currentTimeDisplay.split(' ')[2];

    setDateString(currentDateString);
    setTimeString(currentTimeString);
    setInitialTimeString(currentTimeString);
    setTwelveHourPeriod(currentTwelveHourPeriod);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /************************************************************
   *         Get Primary Contact from contacts array          *
   * -------------------------------------------------------- *
   *  1. If there are multiple Primary set, the order is:     *
   *     Phone > Email > Other                                *
   *  2. If no priority contact exists, then order will be:   *
   *     Work > Assistant Phone > Mobile > Home > Email >     *
   *     Assistant email > Personal Email > Message > Fax     *
   ************************************************************/
  const getPrimaryContact = useCallback((contacts) => {
    let primaryContact = null;
    if (contacts) {
      const primaryContacts = contacts.filter((c) => c.primary);

      if (primaryContacts.length > 0) {
        // has primary contacts
        CONTACT_PRIORITIES.forEach((contactType) => {
          const contact = primaryContacts.find((c) => c.contactType === contactType);
          if (contact && !primaryContact) {
            primaryContact = contact;
            return;
          }
        });
      } else {
        // no primary contacts
        CONTACT_PRIORITIES.forEach((contactType) => {
          const contact = contacts.find((c) => c.contactType === contactType);
          if (contact && !primaryContact) {
            primaryContact = contact;
            return;
          }
        });
      }
    }

    return primaryContact || {};
  }, []);

  const onSelectPerson = useCallback(
    (person) => {
      //only "master-data" people have private contacts
      if (person && person.type && person.type !== 'Outlook' && person._id) {
        dispatch(getPersonPrivateContacts(person._id))
          .then((response) => {
            const privateContacts = response?.body?.privateContacts || [];
            setRecipientIdPrivateContacts(privateContacts);
          })
          .catch((error) => {
            console.error(error);
          });
      }

      const updatedCallTodo = {
        ...callTodo,
        recipientId: person,
        recipientName: person.name,
        contact: getPrimaryContact(person.contacts),
        companyName: person.companyId && person.companyId.name,
      };

      updatedCallTodo.contactInfo = updatedCallTodo.contact && updatedCallTodo.contact.contact;

      if (updatedCallTodo.contact) {
        setTimeout(() => {
          if (notesInput.current?.textarea?.focus) {
            notesInput.current.textarea.focus();
          }
        }, 100);
      }

      //reset desk address book search results after person select

      setDeskContactResults([]);
      setPersonSearchResults([]);
      setOutlookSearchResults([]);

      callForm.outlook && (callForm.outlook.results = []);

      onChange && onChange(updatedCallTodo);
    },
    [callForm.outlook, callTodo, dispatch, getPrimaryContact, onChange]
  );

  const searchPerson = useDebouncedCallback((name, desk) => {
    name = name?.trim();

    getPeopleByTypeBuckets(name)
      .then((result) => {
        setPersonSearchResults(result.data);
      })
      .catch((error) => {
        console.error(error);
      });

    dispatch(searchDeskContacts(desk, name))
      .then((result) => {
        const deskPeople = result.body || [];
        setDeskContactResults(deskPeople);
      })
      .catch((error) => {
        console.error(error);
      });

    getOutlookContacts(name, desk, dispatch)
      .then(setOutlookSearchResults)
      .catch((error) => {
        console.error(error);
      });
  }, 400);

  const validateCallForm = useCallback(() => {
    const missingFields = [];

    if (
      !(
        callTodo?.recipientId ||
        (callTodo?.recipientName && callTodo?.recipientName.trim().length > 0)
      )
    ) {
      missingFields.push('Name');
    }
    if (!callTodo?.status) {
      missingFields.push('Status');
    }
    if (!callTodo?.contact?.contact) {
      missingFields.push('Contact Info');
    }
    if (!callTodo?.contact?.contactType) {
      missingFields.push('Type');
    }

    if (missingFields.length) {
      const lf = new Intl.ListFormat('en');
      const formatted = lf.format(missingFields);
      const areIs = missingFields.length > 1 ? 'are' : 'is';
      const errorMessage = `${formatted} ${areIs} required to create a Call Record.`;
      addToast(errorMessage, { appearance: 'error', autoDismiss: true });
      return false;
    }

    return true;
  }, [
    addToast,
    callTodo?.contact?.contact,
    callTodo?.contact?.contactType,
    callTodo?.recipientId,
    callTodo?.recipientName,
    callTodo?.status,
  ]);

  const submit = useCallback(
    async (e) => {
      e.preventDefault();

      const isValid = validateCallForm();
      if (!isValid) {
        return;
      }

      // Add description as first element of notes
      callTodo.notes = [{ note: callTodo.description }];

      setIsSaving(true);
      // onSave creates endpoint call Promise via call-todo.saveCall
      onSave &&
        onSave(callTodo).finally(() => {
          setIsSaving(false);
        });
    },
    [callTodo, onSave, validateCallForm]
  );

  const isExistingOutlookContact = useCallback(
    () =>
      callTodo.recipientId?.outlook &&
      callTodo.contact?.contact &&
      (existingContactPhone(callTodo.recipientId, callTodo.contact) ||
        existingContactNotPhone(callTodo.recipientId, callTodo.contact)),
    [callTodo.contact, callTodo.recipientId]
  );

  const outlook = { results: outlookSearchResults };
  const userRegion = navigator.language.split('-')[1];

  const isPhoneOrFax = useMemo(
    () => callTodo?.contact && /Phone|Fax Number/.test(callTodo?.contact?.contactType),
    [callTodo?.contact]
  );

  const currentStatus = useMemo(() => {
    const foundStatus = statuses?.find(({ status }) => status === callTodo.status);

    return foundStatus || statuses?.[0];
  }, [callTodo.status, statuses]);

  callTodo.status = currentStatus && currentStatus.status;

  const statusChildren = !!statuses && (
    <StatusInput
      data-cy={CALL_FORM.STATUS_TEXT_BOX}
      options={statuses.filter((code) => code.name && code.status)}
      value={currentStatus}
      onChange={(value) => {
        onChange &&
          onChange({
            ...callTodo,
            status: value.status,
          });
      }}
    />
  );

  //combine "master data" public contacts with "master data" private contacts (that their desk has access to)
  const masterDataContacts = callTodo.recipientId
    ? (callTodo.recipientId.contacts || []).concat(recipientIdPrivateContacts || [])
    : [];

  //combine masterDataContacts with desk address book entries (just fall-through logic, should never be both?)
  const existingContacts = masterDataContacts
    .concat(deskAddressBookEntryContacts)
    .map(({ _id, contactType = 'Unknown', contact = '', primary }) => ({
      _id,
      contactType,
      value: contact,
      primary,
      private: contactType && !contact,
    }));

  const contactAddable =
    !isExistingOutlookContact() &&
    callTodo.recipientId?.type !== 'Employee' &&
    (!callTodo.recipientId ||
      (callTodo.recipientId?._id?.length > 0 && callTodo.recipientId?.canEdit) ||
      callTodo.recipientId?.type === 'Desk Contact');

  let tooltipMessage = undefined;

  if (callTodo.recipientId?.type === 'Employee') {
    tooltipMessage = 'Contact information for an employee can only be edited (by them) in Workday.';
  } else if (callTodo.recipientId?.type === 'Client') {
    tooltipMessage = 'Please contact a client team member to make changes to this client profile.';
  } else {
    tooltipMessage = 'Phonesheet cannot edit Outlook (O) or Personal (P) contacts.';
  }

  const handleDatetimeChange = useCallback(
    (dateString, timeString, twelveHourPeriod) => {
      const od = new Date(`${dateString}, ${timeString} ${twelveHourPeriod}`);
      callTodo.occurrence_date = od.getTime();
      onChange && onChange(callTodo);
    },
    [callTodo, onChange]
  );

  const renderDatetimePicker = useCallback(
    () => (
      <DatePicker
        timeIncluded
        inputFieldReadOnly
        timeString={timeString}
        dateString={dateString}
        twelveHourPeriod={twelveHourPeriod}
        onChangeTime={(time) => {
          setTimeString(time);
          handleDatetimeChange(dateString, time, twelveHourPeriod);
        }}
        onChangeTwelveHourPeriod={(period) => {
          setTwelveHourPeriod(period);
          handleDatetimeChange(dateString, timeString, period);
        }}
        clearTime={() => {
          setTimeString(initialTimeString);
          handleDatetimeChange(dateString, initialTimeString, twelveHourPeriod);
        }}
        onChange={(date) => {
          setDateString(date);
          handleDatetimeChange(date, timeString, twelveHourPeriod);
        }}
      />
    ),
    [dateString, handleDatetimeChange, initialTimeString, timeString, twelveHourPeriod]
  );

  const handleOnChangePersonInput = useCallback(
    (value) => {
      callTodo.recipientId = null;
      callTodo.recipientName = value;

      value && searchPerson(value, deskId);
      onChange && onChange(callTodo);
    },
    [callTodo, deskId, onChange, searchPerson]
  );

  const handleOnChangePhoneInput = (value) => {
    delete callTodo.contact._id;
    const existing = existingContactPhone(callTodo.recipientId, callTodo.contact);
    if (existing) {
      callTodo.contact._id = existing._id;
    }

    onChange &&
      onChange({
        ...callTodo,
        contactInfo: value,
        contact: {
          ...callTodo?.contact,
          contact: value,
        },
      });
  };

  const phoneNumberIsValid = useMemo(() => {
    const hasContactInfo = ![null, undefined].includes(callTodo?.contactInfo);

    const isPhoneType = callTodo?.contact?.contactType?.includes('Phone');
    const isFaxType = callTodo?.contact?.contactType?.includes('Fax Number');

    const e164ContactInfo = fromStringToE164(callTodo.contactInfo);
    const hasExtension = ![null, undefined].includes(e164ContactInfo?.extension);
    const hasCountryCode = ![null, undefined].includes(e164ContactInfo?.countryCode);

    const isValid =
      (isPhoneType || isFaxType) && hasContactInfo && (hasExtension || hasCountryCode);

    return isValid;
  }, [callTodo]);

  return (
    <form className={styles.container} onSubmit={submit} data-cy={CALL_FORM.CALL_FORM}>
      <div className={styles.mainContainer}>
        <div className={styles.subContainer}>
          <div className={styles.section}>{statusChildren}</div>

          <div
            data-cy={CALL_FORM.NAME_TEXT_BOX}
            className={classnames(styles.section, styles.nameInput)}
          >
            <PersonInput
              inputRef={nameInput.current}
              results={[...personSearchResults, ...deskContactResults]}
              outlook={outlook}
              showOutlook={true}
              value={callTodo.recipientName}
              onChange={handleOnChangePersonInput}
              onSelect={onSelectPerson}
              focus={!isNotesFocus}
            />
          </div>
          <div className={classnames(styles.section, styles.contactInput)}>
            <div
              id="contact-type-selector"
              data-cy={CALL_FORM.TYPE_SELECT_BOX}
              style={{ marginTop: 6 }}
            >
              <ContactTypeSelector
                value={{
                  contactType: callTodo.contact.contactType || 'Unknown',
                  value: callTodo.contact.contact,
                }}
                existing={existingContacts}
                onChange={({ contactType = 'Unknown', value }) => {
                  onChange &&
                    onChange({
                      ...callTodo,
                      contact: { contactType, contact: value },
                      contactInfo: value,
                    });
                }}
                extSeparator=" x"
                addable={contactAddable}
                tooltipMessage={tooltipMessage}
                cypressTags={{
                  existingContact: CONTACT_INPUT.EXISTING_CONTACT,
                  addContact: CONTACT_INPUT.ADD_CONTACT,
                  contactType: CONTACT_INPUT.CONTACT_TYPE,
                }}
              />
            </div>
            {callTodo?.contact ? (
              isPhoneOrFax ? (
                <div
                  id="phone-or-fax-input"
                  data-cy={CALL_FORM.PHONE_TEXT_BOX}
                  style={{ marginTop: 2, width: '100%' }}
                >
                  <PhoneInput
                    userRegion={userRegion}
                    value={callTodo?.contactInfo}
                    title={callTodo.contact.contactType}
                    onChange={handleOnChangePhoneInput}
                    disabled={!contactAddable}
                    cypressTags={{
                      phoneNumberInput: CONTACT_INPUT.PHONE_NUMBER_INPUT,
                    }}
                  />
                  {!phoneNumberIsValid && callTodo?.contactInfo && (
                    <p style={{ color: 'red', fontSize: '12px', textAlign: 'center' }}>
                      Invalid Number
                    </p>
                  )}
                </div>
              ) : (
                <div
                  id="non-phone-or-fax-input"
                  data-cy={CALL_FORM.NORMAL_TEXT_BOX}
                  style={{ marginTop: 6, minWidth: 250, flex: 1 }}
                >
                  <Input
                    key={callTodo.contactInfo}
                    value={callTodo.contactInfo}
                    title={callTodo.contact.contactType}
                    onChange={(value) => {
                      const existing = existingContactNotPhone(
                        callTodo.recipientId,
                        callTodo.contact
                      );

                      const updatedCallTodo = {
                        ...callTodo,
                        contactInfo: value,
                        contact: {
                          ...callTodo.contact,
                          contact: value,
                          _id: existing && existing._id ? existing._id : undefined,
                        },
                      };

                      onChange && onChange(updatedCallTodo);
                    }}
                    disabled={!contactAddable}
                    focus={true}
                  />
                </div>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={styles.subContainer}>
          <div
            data-cy={CALL_FORM.DATE_TEXT_BOX}
            className={classnames(styles.section, styles.datetimePicker)}
          >
            {renderDatetimePicker()}
          </div>
          <div
            data-cy={CALL_FORM.NOTES_TEXT_BOX}
            className={classnames(styles.section, styles.notesInput)}
          >
            <TextArea
              inputRef={notesInput}
              id="notes-input"
              title="Notes"
              onChange={(notes) => {
                callTodo.description = notes;
                onChange && onChange(callTodo);
              }}
              value={callTodo.description}
              focus={isNotesFocus}
            />
          </div>
          <div className={styles.doneButtons}>
            <DoneButtons
              isActive={true}
              isSaving={isSaving}
              onDone={() => {
                onCancel && onCancel();
              }}
              cypressSaveTag={CALL_FORM.CHECKMARK_BUTTON}
              cypressCloseTag={CALL_FORM.CANCEL_BUTTON}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

const withStyles = styled({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainContainer: {
    display: 'flex',
    background: colors.contentBackground,
    border: `1px solid ${colors.darkBorder}`,
    padding: 6,
    [`@media ${sizes.mediumBreakpoint}`]: {
      flexDirection: 'column',
    },
    [`@media ${sizes.mobileBreakpoint}`]: {
      paddingTop: 15,
    },
  },
  subContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    [`@media ${sizes.mobileBreakpoint}`]: {
      flexDirection: 'column',
    },
  },
  section: {
    margin: 6,
    [`@media ${sizes.mobileBreakpoint}`]: {
      margin: '0 15px 15px',
    },
  },
  button: helpers.merge(elements.reset, elements.actionable, {
    padding: 10,
    lineHeight: 0,
  }),
  nameInput: {
    flex: 1,
    [`@media ${sizes.mediumBreakpoint}`]: {
      minWidth: 200,
    },
    [`@media ${sizes.smallBreakpoint}`]: {
      minWidth: 70,
    },
    [`@media ${sizes.mobileBreakpoint}`]: {
      minWidth: 70,
    },
  },
  contactInput: {
    display: 'flex',
    marginTop: '0px!important',
  },
  notesInput: {
    flex: 1,
    minWidth: 70,
  },
  doneButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '0!important',
    marginBottom: '0!important',
  },
  alertContainer: {
    textAlign: 'center',
    width: '450px',
    fontSize: '12px',
    background: 'white',
    opacity: 1,
    padding: '40px',
    boxShadow: '0 20px 75px rgba(0, 0, 0, 0.23)',
    color: '#4a4a4a',
    border: '1px solid #4a4a4a',
  },
  alertButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    margin: '5px',
  }),
  private: {
    position: 'absolute',
  },
  privateLabel: {
    fontSize: 12,
    fontWeight: '100',
    display: 'block',
    position: 'relative',
    cursor: 'pointer',
    userSelect: 'none',
    ' input': {
      postiion: 'absolute',
      opacity: 0,
      cursor: 'pointer',
    },
    ' input:checked': {
      display: 'block',
    },
    ' .privateCheckbox:after': {
      left: '9px',
      top: '5px',
      width: '5px',
      height: '10px',
      border: 'solid white',
      borderWidth: '0 3px 3px 0',
      transform: 'rotate(45deg)',
    },
  },
  privateCheckbox: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 15,
    width: 15,
    backgroundColor: '#eee',
    '&:after': {
      content: '',
      position: 'absolute',
      display: 'none',
    },
  },
});

const withState = connect((state) => {
  const callForm = state?.callForm;
  const deskId = state?.desk?.current?._id;
  const desk = state?.desk?.current;
  const user = state?.user;

  return { callForm, deskId, user, desk };
});

const CallForm = withState(withStyles(withHooksHOC(Component)));

export default CallForm;
