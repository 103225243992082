import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from 'react-free-style';
import { connect } from 'react-redux';
import { isEqual, pickBy } from 'lodash';
import * as elements from '../../styles/elements';

import { Spinner } from '@united-talent-agency/components';

import Wrapper from './Wrapper';
import CallListWrapper from './CallListWrapper';

import { setDesk as setDeskAction } from '@united-talent-agency/julius-frontend-store';
import { updateSearch } from '../../api/search';
import { datadogRum } from '@datadog/browser-rum';
import { WIDTH_LIMIT } from '../../support/windowSize';
import { getStatuses } from '../../api/statuses';

// Flag used to register what User is active in the application for DataDog.
let DATA_DOG_SET = false;

const Component = ({ user, desk, dispatch, status, isLoading, styles }) => {
  // Refs
  const prevWidth = useRef(WIDTH_LIMIT + 1);
  const windowLocation = useRef(new URL(window.location.href));

  // States
  const [fetchCounts, setFetchCounts] = useState({});
  const [totalCounts, setTotalCounts] = useState({});
  const [filtersExpanded, setFiltersExpanded] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [selectExcept, setSelectExcept] = useState(new Set());
  const [filters, setFilters] = useState(null);
  const [deskStatuses, setDeskStatuses] = useState([]);
  const [lastDesk, setLastDesk] = useState(desk);

  const queryStringToObject = useCallback((queryString) => {
    if (!queryString) {
      return {};
    }

    const URLSearchParamsInstance = new URLSearchParams(queryString);
    return Object.fromEntries(URLSearchParamsInstance.entries());
  }, []);

  const search = useMemo(
    () =>
      lastDesk?._id === desk?._id
        ? {
            ...queryStringToObject(windowLocation.current?.searchParams?.toString()),
            deskId: desk._id,
          }
        : { deskId: desk._id },
    [desk._id, lastDesk?._id, queryStringToObject]
  );

  const getAvailableQueryFilters = useCallback(
    async (queryFilters, deskId = null) => {
      if (!queryFilters || !queryFilters.length) {
        return;
      }
      let statusList = [];
      if (deskId) {
        let tempDeskStatus = await getStatuses(deskId);

        statusList = tempDeskStatus?.map((_status) => _status?.status);
      } else {
        statusList = status.map((_status) => _status.status);
      }
      const _filters = queryFilters.split(',');
      const availableFilters = _filters.filter((filter) => statusList.includes(filter));
      return availableFilters.length ? availableFilters.join(',') : undefined;
    },
    [status]
  );

  useEffect(() => {
    const update = async () => {
      if (filters === null && status) {
        const onlyDeskIdOnSearch = !Object.keys(search).some((key) => key !== 'deskId');
        const sameDesk = lastDesk?._id === desk?._id;
        const currentDesk = user?.phoneSheetFilter?.find(({ deskId }) => deskId === desk?._id);
        const hasDifferentURL = !isEqual(currentDesk, search);

        if (user?.phoneSheetFilter && !onlyDeskIdOnSearch && sameDesk && hasDifferentURL) {
          let state = user?.phoneSheetFilter;

          const foundIndex = state?.findIndex(({ deskId }) => deskId === desk?._id);
          const value = {
            ...search,
            filter: await getAvailableQueryFilters(search?.filter),
            deskId: desk?._id,
          };

          delete value?.desk;

          if (foundIndex < 0) {
            state = [...state, value];
          } else {
            state[foundIndex] = value;
          }

          setFilters(state);
        } else {
          setFilters(
            user?.phoneSheetFilter?.map((queries) => {
              delete queries?.desk;

              return queries;
            }) ?? null
          );
        }
      }
    };

    update();
  }, [
    desk?._id,
    filters,
    getAvailableQueryFilters,
    lastDesk?._id,
    search,
    status,
    user,
    user?.phoneSheetFilter,
  ]);

  useEffect(() => {
    // if no configuration is active, DD should be set to true
    if (!datadogRum.getInitConfiguration()) {
      DATA_DOG_SET = true;
    }
  }, []);

  useEffect(() => {
    const windowResizeListener = () => {
      // While resizing, if screen size got smaller than mediumBreakpoint, filters should be collapsed
      if (prevWidth.current > WIDTH_LIMIT && window.innerWidth <= WIDTH_LIMIT) {
        setFiltersExpanded(false);
      }
      prevWidth.current = window.innerWidth;
    };

    windowResizeListener();

    window.addEventListener('resize', windowResizeListener);

    return () => {
      window.removeEventListener('resize', windowResizeListener);
    };
  }, []);

  const fetchDesk = useCallback(
    async (desk) => {
      await dispatch(setDeskAction(desk || {}));
    },
    [dispatch]
  );

  useEffect(() => {
    // Runs when user changes only
    if (desk) {
      fetchDesk(desk);
    } else if (user?.deskIds?.length) {
      fetchDesk(user.deskIds[0]);
    }
  }, [desk, fetchDesk, user.deskIds]);

  useEffect(() => {
    // Flag allows this to fire 1x per user-login, as desired.
    if (user && user.azure_id && !DATA_DOG_SET) {
      datadogRum.setUser({
        id: user.azure_id,
        name: `${user.last_name}, ${user.first_name}`,
        email: user.email,
      });

      datadogRum.startSessionReplayRecording();
      DATA_DOG_SET = true;
    }
  }, [user]);

  const updateFilters = useCallback(
    (_search) => {
      setFilters((oldState) => {
        const foundIndex = oldState?.findIndex(({ deskId }) => deskId === desk?._id);

        oldState[foundIndex] = {
          ..._search,
          deskId: desk?._id,
        };

        return [...oldState];
      });
    },
    [desk?._id]
  );

  const currentDeskSearch = useMemo(() => {
    const found = filters?.find(({ deskId }) => deskId === desk?._id);

    if (!found) {
      setFilters((oldState) => {
        if (oldState === null) {
          return oldState;
        }
        return [...oldState, { deskId: desk?._id }];
      });
    }

    return pickBy(found, (val) => ![undefined, null].includes(val));
  }, [desk?._id, filters]);

  const updateURLSearch = useCallback((newSearch) => {
    const urlWithoutQueries = windowLocation.current?.href?.replace(/\/\?.*/g, '/');
    const newWindowLocation = new URL(urlWithoutQueries);

    Object.entries(newSearch).forEach(([key, value]) => {
      if (![undefined, null].includes(value)) {
        newWindowLocation?.searchParams?.set(key, value);
      }
    });

    newWindowLocation?.searchParams?.sort();

    windowLocation.current = newWindowLocation;

    window.history.replaceState(undefined, '', windowLocation.current);
  }, []);

  const onlyDeskIdOnSearch = useMemo(
    () => !Object.keys(search).some((key) => key !== 'deskId'),
    [search]
  );
  const sameDesk = useMemo(() => lastDesk?._id === desk?._id, [desk?._id, lastDesk?._id]);

  const hasDifferentURL = useMemo(
    () => !isEqual(currentDeskSearch, search),
    [currentDeskSearch, search]
  );

  /**
   * Updates the current desk search (inside filters) and update it on database.
   */
  useEffect(() => {
    const update = async () => {
      if (filters !== null) {
        if (currentDeskSearch && (onlyDeskIdOnSearch || (sameDesk && hasDifferentURL))) {
          const newSearch = {
            ...currentDeskSearch,
            filter: await getAvailableQueryFilters(
              currentDeskSearch?.filter,
              currentDeskSearch?.deskId
            ),
            deskId: desk?._id,
          };

          delete newSearch?.desk;

          updateURLSearch(newSearch);
          updateSearch(newSearch);

          setLastDesk(desk);
        }
      }
    };

    update();
  }, [
    currentDeskSearch,
    desk,
    filters,
    getAvailableQueryFilters,
    hasDifferentURL,
    onlyDeskIdOnSearch,
    sameDesk,
    search,
    updateURLSearch,
    deskStatuses,
  ]);

  const resetSearch = useCallback(() => {
    updateFilters();
  }, [updateFilters]);

  const handleOnChangeDesk = useCallback(
    async (selectedDesk) => {
      fetchDesk(selectedDesk);
      const statuses = await getStatuses(selectedDesk._id);
      setDeskStatuses(statuses);
    },
    [fetchDesk]
  );

  const navigate = useCallback(
    (_search) => {
      updateFilters(_search);
    },
    [updateFilters]
  );

  if (!user?.deskIds?.length) {
    const hasDeskAssigned = !!user?.deskIds?.[0]?._id;

    if (!isLoading && !hasDeskAssigned) {
      return (
        <h5 className="m-4">
          No current office groups configured. Please contact the Service Desk at x3900
        </h5>
      );
    }

    return (
      <div className={styles.spinner}>
        <Spinner size={60} />
      </div>
    );
  }

  if (!desk || !currentDeskSearch?.deskId) {
    return <></>;
  }
  return (
    <Wrapper
      user={user}
      search={currentDeskSearch}
      fetchCounts={fetchCounts}
      totalCounts={totalCounts}
      filtersExpanded={filtersExpanded}
      navigate={navigate}
      setSelectAll={setSelectAll}
      setSelectExcept={setSelectExcept}
      changeFiltersExpanded={setFiltersExpanded}
      onChangeDesk={handleOnChangeDesk}
      resetSearch={resetSearch}
      deskStatuses={deskStatuses}
    >
      <CallListWrapper
        search={currentDeskSearch}
        fetchCounts={fetchCounts}
        totalCounts={totalCounts}
        selectAll={selectAll}
        selectExcept={selectExcept}
        setSelectAll={setSelectAll}
        setFetchCounts={setFetchCounts}
        setTotalCounts={setTotalCounts}
        setSelectExcept={setSelectExcept}
      />
    </Wrapper>
  );
};

const withStyles = styled({
  spinner: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  link: elements.link,
});

const withState = connect(({ user, desk = {}, isLoading }) => {
  return { user, desk: desk.current, isLoading, status: desk.status || [] };
});

const Home = withStyles(withState(Component));

export default Home;
