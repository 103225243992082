import React, { useEffect, useState } from 'react';
import { DatePicker } from '@united-talent-agency/components';
import { isValidDateTime, toDateTimeString } from '../../../support/date';
import { CALL_ROW } from '../../../support/cypressTags';
import EllipsisText from '../EllipsisText';

/**
 * Inline date picker
 * @param {number} occurrenceDate: A number representing the milliseconds elapsed between
 *    1 January 1970 00:00:00 UTC and the given value.
 * @param {function} onSave: hook for saving an updated Date
 * @param {Object} styles: provided styling classes
 * @returns {JSX.Element}
 * @constructor
 */
const CallDate = ({ occurrenceDate, onSave, styles }) => {
  const initialDateTime = new Date(occurrenceDate);
  const [dateTime, setDateTime] = useState(initialDateTime);

  const _date = (d) => `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
  const _time = (d) => {
    let hours = d.getHours();
    hours = hours % 12;
    hours = hours ? hours : 12;
    return `${hours}:${d.getMinutes()}`;
  };
  const _amPm = (d) => (d.getHours() > 12 ? 'pm' : 'am');

  const [date, setDate] = useState(_date(initialDateTime));
  const [time, setTime] = useState(_time(initialDateTime));
  const [twelveHour, setTwelveHour] = useState(_amPm(initialDateTime));

  const [editing, setEditing] = useState(false);
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    const newDateTime = new Date(`${date}, ${time} ${twelveHour}`);
    if (!editing && newDateTime.getTime() !== occurrenceDate) {
      if (updated) {
        setUpdated(false);
        setDateTime(newDateTime);
        onSave({ occurrence_date: newDateTime.getTime() });
      } else {
        const newDateTime = new Date(occurrenceDate);
        setDateTime(newDateTime);
        setDate(_date(newDateTime));
        setTime(_time(newDateTime));
        setTwelveHour(_amPm(newDateTime));
      }
    }
  }, [updated, date, time, twelveHour, occurrenceDate, onSave, editing]);

  const onBlur = () => {
    setUpdated(true);
    setEditing(false);
  };

  return (
    <>
      {editing ? (
        <td data-cy={CALL_ROW.DATE}>
          <DatePicker
            timeIncluded
            onChangeTime={setTime}
            onChangeTwelveHourPeriod={setTwelveHour}
            timeString={time}
            twelveHourPeriod={twelveHour}
            dateString={date}
            onChange={setDate}
            onBlur={onBlur}
            inputFieldReadOnly
            focusPicker
            dateButtonCypressTag={CALL_ROW.DATE_DAY_BUTTON}
            dateLabelCypressTag={CALL_ROW.DATE_INNER_LABEL}
          />
        </td>
      ) : (
        <td
          data-cy={CALL_ROW.DATE}
          className={styles.date}
          onClick={() => {
            setEditing(true);
          }}
        >
          <div>
            <time dateTime={isValidDateTime(dateTime) ? dateTime.toISOString() : ''}>
              <EllipsisText text={toDateTimeString(dateTime)} />
            </time>
          </div>
        </td>
      )}
    </>
  );
};

export default CallDate;
