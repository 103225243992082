// Import CSS before React components.
import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import 'normalize.css/normalize.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-custom-scroll/dist/customScroll.css';
import 'react-day-picker/lib/style.css';
import 'react-simple-dropdown/styles/Dropdown.css';
import Modal from 'react-modal';

import { Provider } from 'react-redux';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { LDProvider } from 'launchdarkly-react-client-sdk';

import { createStoreWithOptions } from '@united-talent-agency/julius-frontend-store';
import { OnyxThemeProvider } from '@united-talent-agency/components';

import './styles/card.css';
import './styles/checkbox.css';
import './styles/data-grid.css';
import './styles/scroll.css';
import './styles/select.css';
import Routes from './routes';
import { initializeDataDog } from './support/dataDog';
import { apiServerUrl } from './support/urls';
import { ToastProvider } from 'react-toast-notifications';

const store = createStoreWithOptions({ apiServerUrl });
const LAUNCHDARKLY_CLIENT_ID = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID;

Modal.setAppElement(document.getElementById('app'));

/**
 * Data Dog RUM tracking initialization
 *
 * active host is interrogated against the allowable env flags using this map:
 *    "prod": phonesheet.unitedtalent.com
 *    "staging": phonesheet.staging.unitedtalent.com
 *    "dev": phonesheet.dev.unitedtalent.com
 *    "local": localhost
 */
initializeDataDog(window.location.hostname, ['staging', 'prod']);

function mount(App) {
  return render(
    <React.StrictMode>
      <LDProvider clientSideID={LAUNCHDARKLY_CLIENT_ID}>
        <Provider store={store}>
          <ToastProvider>
            <OnyxThemeProvider>
              <Router>
                <App />
              </Router>
            </OnyxThemeProvider>
          </ToastProvider>
        </Provider>
      </LDProvider>
    </React.StrictMode>,
    document.getElementById('app')
  );
}

mount(Routes);

if (module.hot) {
  module.hot.accept('./routes', () => {
    mount(require('./routes').default);
  });
}

if (window.Cypress) {
  window.store = store;
}
