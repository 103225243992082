import React, { useCallback, useEffect, useState } from 'react';
import { notify } from 'react-notify-toast';
import { connect } from 'react-redux';

import CallList from '../../CallList';
import { fullRefresh as handleFullRefreshResult, loadMore as handleLoadMoreResult } from './Helper';
import {
  createShortcuts,
  filterInputEvent,
  keyboard,
  stringifyKey,
} from '../../../support/keyboard';
import { getCommunications } from '../../../api/communications';
import { DEFAULT_CALL_FETCH_LIMIT } from '../../../api/communications';
import { useDebouncedCallback } from 'use-debounce';

const REFRESH_RATE = (process.env.REACT_APP_REFRESH_RATE_SECONDS || 10) * 1000;

/**
 * @typedef {object} Search
 * @property {string} desk: current desk mongo document id
 * @property {string} [favorite]: 1 or 0 or undefined
 * @property {string} [filter]: comma separated string of status abbreviations
 * @property {string} [query]: current user-search entered into the search bar
 **/
const Component = ({
  search,
  statuses,
  hideFutureCalls,
  totalCounts,
  setTotalCounts,
  fetchCounts,
  setFetchCounts,
  callTodos,
  editCallTodo,
  selectAll,
  setSelectAll,
  selectExcept,
  setSelectExcept,
}) => {
  // States
  const [focused, setFocused] = useState(0);
  const [communications, setCommunications] = useState([]);
  const [statusOfLoadingMore, setStatusOfLoadingMore] = useState(null);

  const move = useCallback(
    (e, pos) => {
      e.preventDefault();

      setFocused(Math.min(Math.max(0, focused + pos), (callTodos?.length || 0) - 1));
    },
    [callTodos?.length, focused]
  );

  useEffect(() => {
    const keyboardListener = filterInputEvent(
      createShortcuts({
        [stringifyKey('up')]: (e) => move(e, -1),
        [stringifyKey('j')]: (e) => move(e, -1),
        [stringifyKey('down')]: (e) => move(e, 1),
        [stringifyKey('h')]: (e) => move(e, 1),
        [stringifyKey('v')]: () => {
          /** keep */
        },
      })
    );

    keyboard.addListener(keyboardListener);

    return () => {
      keyboard.removeListener(keyboardListener);
    };
  }, [move]);

  /**
   * Refresh calls entirely (initial load)
   *
   * @param {Search} search: params for search
   **/
  const fullRefresh = useDebouncedCallback(
    (search) => {
      const { favorite, filter, query, deskId } = search || {};

      const params = {
        favorite,
        query,
        deskId,
        filter,
        hideFutureCalls,
      };

      const promises = statuses
        .filter((status) => status !== '')
        .map((item) => {
          let limit = DEFAULT_CALL_FETCH_LIMIT;
          if (!!fetchCounts[item] && DEFAULT_CALL_FETCH_LIMIT < fetchCounts[item]) {
            limit = fetchCounts[item];
          }
          return getCommunications(
            { ...params, filter: item },
            {
              skip: 0,
              limit,
            }
          );
        });

      return Promise.all(promises)
        .then((results) =>
          handleFullRefreshResult(
            results,
            statuses,
            setFetchCounts,
            setTotalCounts,
            setCommunications
          )
        )
        .catch(() => {
          notify.show('Failed to load calls', 'error');
        });
    },
    500,
    {
      leading: false,
      trailing: true,
      maxWait: REFRESH_RATE,
    }
  );

  /**
   * Load more calls per status
   *
   * @param {Search} search: params for search
   * @param {string} status: status of calls to load
   **/
  const loadMore = useCallback(
    (search, status) => {
      const { favorite, query, deskId } = search || {};
      const params = {
        favorite,
        query,
        deskId,
        filter: status,
        hideFutureCalls,
      };
      setStatusOfLoadingMore(status);
      getCommunications(params, { skip: fetchCounts[status] })
        .then((result) => {
          handleLoadMoreResult(
            result,
            status,
            fetchCounts,
            setFetchCounts,
            communications,
            setCommunications
          );
          setStatusOfLoadingMore(null);
        })
        .catch(() => {
          setStatusOfLoadingMore(null);
          notify.show('Failed to load more calls', 'error');
        });
    },
    [communications, fetchCounts, hideFutureCalls, setFetchCounts]
  );

  useEffect(() => {
    // either changing desk and persisting filter call refreshCalls
    // to prevent consequent refreshCalls, use debounce here
    fullRefresh(search);
    const subscription = setInterval(() => {
      fullRefresh(search);
    }, REFRESH_RATE);

    return () => {
      clearInterval(subscription);
    };
  }, [fullRefresh, search]);

  return (
    <div>
      <CallList
        communications={communications}
        totalCounts={totalCounts}
        fetchCounts={fetchCounts}
        editCallTodo={editCallTodo}
        search={search}
        statusOfLoadingMore={statusOfLoadingMore}
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        selectExcept={selectExcept}
        setSelectExcept={setSelectExcept}
        onLoadMore={(status) => loadMore(search, status)}
        onEditDone={() => fullRefresh(search)}
      />
    </div>
  );
};

const withState = connect((store, ownProps) => {
  const { desk = {} } = store;
  const searching = ownProps && ownProps.search && ownProps.search.filter;
  let statuses = searching ? ownProps.search.filter?.split(',') : desk.status;
  const { calls, preview } = store.callTodo ? store.callTodo : {};
  const { editCallTodo } = store.callForm ? store.callForm : {};
  const { callTodos } = calls || {};

  if (!searching) {
    statuses = desk.status ? statuses.map((s) => s.status) : [];
  }

  const hideFutureCalls =
    store.user && desk.current && desk.current.settings && desk.current.settings.hideFutureCalls;

  return {
    callTodos,
    statuses,
    hideFutureCalls,
    preview,
    editCallTodo,
    statusArray: desk.status,
  };
});

const CallListWrapper = withState(Component);

export default CallListWrapper;
